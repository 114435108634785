import { UseInfiniteQueryOptions, useInfiniteQuery } from "react-query";
import { FilterPublicProductInput } from "../api/generated";
import { useGraphql } from "./useGraphql";
import useGraphqlError from "./useGraphqlError";

export interface useProductProps {
  input: FilterPublicProductInput;
  search?: string;
  option?: UseInfiniteQueryOptions;
  limit?: number;
  handleError?: ((err: unknown) => void) | undefined;
}

/**
 *
 * Product List manager
 *
 * @param input
 * @param option
 * @param limit
 * @handleError handleError
 * @returns
 */
const useInfinitePublicProduct = ({ input, option, limit, handleError }: useProductProps) => {
  const graphql = useGraphql();
  const { showError } = useGraphqlError();

  const query = useInfiniteQuery(
    ["product", input],
    async ({ pageParam }) => {
      return graphql
        .filterPublicProducts({
          input: {
            ...input,
            filter: {
              pagination: { cursor: pageParam, pageSize: limit ?? 20 },
              ...input.filter,
            },
            search: input.search,
          },
        })
        .then(({ filterPublicProducts }) => filterPublicProducts);
    },
    {
      getNextPageParam: (prev) => {
        return prev.pageInfo.nextCursor ?? undefined;
      },
      onError: handleError ?? showError,
      retry: false,
      ...(option as any),
    },
  );
  return query;
};

export default useInfinitePublicProduct;
