import { create } from "zustand";
import { combine } from "zustand/middleware";
import { persist } from "./persist";
import { FilterExpression } from "libs/data-access/src/lib/api/filter";
import { ProductFilterFields } from "libs/data-access/src/lib/types";

const defaultState: {
  filters?: FilterExpression<ProductFilterFields>;
  pharmacyId?: String;
} = {};

/**
 * Store manager for user authentication and profile
 */

export const useProductFiltersState = create(
  persist(
    {
      key: "productFilters",
    },
    combine(defaultState, (set) => ({
      setProductFilters: (filters?: FilterExpression<ProductFilterFields>) =>
        set((state) => ({ ...state, filters })),
      setPharmacyId: (pharmacyId?: String) => set((state) => ({ ...state, pharmacyId })),

      reset: () =>
        set({
          filters: {},
          pharmacyId: "",
        }),
    })),
  ),
);
