/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import CartDetails from "@gruene-brise/common-ui/lib/webshop/CartDetails";
import { useCartState } from "@gruene-brise/data-access/state/useCartState";
import SelectPharmacy from "@gruene-brise/common-ui/webshop/SelectPharmacy";
import useCurrencyFormatter from "@gruene-brise/data-access/hooks/useCurrencyFormatter";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsX } from "react-icons/bs";
import { CgArrowRight } from "react-icons/cg";
import Button from "@gruene-brise/common-ui/Button";
import { Spinner, Tooltip } from "@chakra-ui/react";

/**
 * Cart shows the list of all items in your cart and pharmacy that has those items
 * @param drawerFeature
 * @returns JSX.Element
 */

export interface CartProp {
  drawerFeature: boolean;
}
const Cart = ({ drawerFeature }: CartProp) => {
  const { isCartOpen, setCartOpen, products } = useCartState();
  const { t } = useTranslation();
  const cartItems = Object.keys(products);
  const numberFormat = useCurrencyFormatter();
  const [hasPharmacy, setHasPharmacy] = useState(false);
  const [lowestPriceTotal, setLowestPriceTotal] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.pageYOffset === 0;

      setIsAtTop(isTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div
        className={`${
          isCartOpen ? "bg-primary fixed top-0 z-50 w-screen block md:hidden" : "hidden"
        }`}
      >
        <div className='w-full flex flex-row justify-between items-center py-5 px-4'>
          <div className='text-white font-bold w-4/5'>{t("Cart")}</div>
          <div className='' onClick={() => setCartOpen(!isCartOpen)}>
            <BsX color='#fff' size={20} />
          </div>
        </div>
      </div>

      <div
        className={`right-0
           ${
             drawerFeature
               ? "fixed h-[100%]"
               : "absolute md:relative xl:relative lg:relative min-h-screen"
           }
            font-gellix mb-10
            ${
              isCartOpen && drawerFeature
                ? "w-screen md:w-[400px]"
                : isCartOpen && !drawerFeature
                ? "w-screen md:w-[500px] xs:top-0 sm:top-12 md:top-8"
                : !isCartOpen && !drawerFeature
                ? "xs:top-0 sm:top-12 md:top-8 w-[0%]"
                : "w-[0%]"
            }
            bg-white
            duration-500 transition-all

            shadow-button_sm
         `}
        style={{ zIndex: 1800 }}
      >
        <div
          onScroll={(e) => {
            if (e.currentTarget.scrollTop === 0) {
              setIsAtTop(true);
            } else {
              setIsAtTop(false);
            }
          }}
          className={`${
            isCartOpen ? "opacity-100" : "opacity-0"
          } duration-500 transition-all right-0  overflow-scroll md:overflow-hidden relative bg-inherit h-full flex flex-col`}
        >
          <div className='bg-white shadow-button_sm px-0 md:px-3'>
            <div className='w-full hidden md:flex flex-row justify-between px-2 mb-1 pt-5 pb-2'>
              <div className='line-clamp-2 text-sm md:text-lg xl:text-lg lg:text-lg font-medium'>
                {t("Cart")}
              </div>

              <div
                className='hidden md:block cursor-pointer'
                onClick={() => setCartOpen(!isCartOpen)}
              >
                <CgArrowRight />
              </div>
            </div>
            <div
              className={` ${isAtTop ? "hidden" : "flex"} md:hidden flex-col fixed z-11111
              w-screen lg:w-[500px] xs:top-0 top-0 max-h-screen
             shadow-md bg-white `}
            >
              <div className='bg-primary fixed top-0 h-[60px] w-full'>
                <div className='w-full flex flex-row justify-between items-center pt-5 pb-5 px-4'>
                  <div className='text-white font-bold w-4/5'>{t("Cart")}</div>
                  <div className='' onClick={() => setCartOpen(!isCartOpen)}>
                    <BsX color='#fff' size={20} />
                  </div>
                </div>
              </div>
              <div className='grid grid-rows-3 mt-[60px]'>
                <div className='flex flex-row pt-4 px-2'>
                  <span className='text-primary-50 mr-1 text-sm'>{t("Your cart has")}</span>
                  <span className='text-fail mr-1 text-sm'>{cartItems.length}</span>
                  {cartItems.length === 1 ? (
                    <span className='text-primary-50 mr-1 text-sm'>{t("item")}</span>
                  ) : (
                    <span className='text-primary-50 mr-1 text-sm'>{t("items")}</span>
                  )}
                </div>

                <div className='border-t border-primary-10 mt-2 px-10' />
                <div className='w-full pb-2 -mt-2 px-3 h-full flex-row justify-between font-gellix items-center flex'>
                  <div className=''>
                    <span className='font-semibold text-[10px] lg:text-[16px] md:text-[16px] sm:text-[16px] xl:text-[16px] text-primary w-[90%] relative line-clamp-2 font-gellix'>
                      {t("Total Price")}
                    </span>

                    <span className='w-full  text-[10px]  text-primary-50 capitalize font-light line-clamp-1 font-gellix'>
                      {t("shipping")}
                    </span>
                  </div>
                  <div className=''>
                    {isLoading ? (
                      <Spinner size='sm' />
                    ) : (
                      <span className='text-[14px] md:text-lg xl:text-lg lg:text-lg font-semibold'>
                        <span className='text-xs font-small text-primary-50'>{t("from")}</span>
                        {numberFormat(lowestPriceTotal ?? 0)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <CartDetails
              className={`${!hasPharmacy && cartItems.length <= 0 ? "h-[100%]" : "max-h-[300px]"}`}
              showPriceDescription
              products={products}
            />

            {cartItems.length > 0 && (
              <div className={`${isAtTop ? "block" : "hidden"} w-full px-2 pb-3`}>
                <div className='w-full px-3 flex flex-row justify-between font-gellix items-center'>
                  <div className=''>
                    <span className='font-semibold text-[10px] lg:text-[16px] md:text-[16px] sm:text-[16px] xl:text-[16px] text-primary w-[90%] relative font-gellix'>
                      {t("Total Price")}
                    </span>

                    <span className='w-full  text-[10px]  text-primary-50 capitalize font-light line-clamp-1 font-gellix'>
                      {t("plus shipping")}
                    </span>
                  </div>
                  <div className=''>
                    {isLoading ? (
                      <Spinner size='sm' />
                    ) : (
                      <span className='text-[14px] md:text-lg xl:text-lg lg:text-lg font-semibold'>
                        <span className='text-xs font-small mr-1 text-primary-50'>{t("from")}</span>
                        {numberFormat(lowestPriceTotal ?? 0)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {cartItems.length > 0 && (
            <div className='h-[70%] px-3 flex flex-col bg-primary-10'>
              <div>
                <div className='w-full flex-row flex justify-between mb-2 pt-4 pr-3'>
                  <div className='line-clamp-3 px-3 text-sm md:text-lg xl:text-md lg:text-md font-medium w-[70%]'>
                    {t("Pharmacy Selection")}
                  </div>
                  <Tooltip
                    label={t("Select a pharmacy and proceed to checkout")}
                    className='cursor-pointer !rounded-xl w-[150px] mr-2 !p-3'
                    placement='left'
                    hasArrow
                  >
                    <div className='bg-primary-25 w-[25px] h-[25px] cursor-pointer text-sm font-medium font-gellix flex justify-center items-center rounded-full'>
                      ?
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className='h-[100%] px-3 scrollbar-hide md:overflow-scroll'>
                <SelectPharmacy
                  onHasPharmacy={(e) => {
                    setHasPharmacy(e);
                  }}
                  products={cartItems
                    .filter((i) => Boolean(products[i]))
                    .map((i) => {
                      return { product: products[i].product, quantity: products[i].selection };
                    })}
                  setLowestPrice={setLowestPriceTotal}
                  setIsLoading={setIsLoading}
                />

                <div className='h-[80%] w-full' />
              </div>
            </div>
          )}
          <div
            className={`${
              isAtTop ? "hidden" : "flex"
            } flex md:hidden w-screen lg:w-[500px] justify-center fixed z-11111 bottom-[55px]`}
          >
            <Button
              variant='contained'
              brand='primary'
              size='md'
              className={"max-w-[200px]"}
              text={t("Back to top")!}
              textClass='font-semibold capitalize'
              onClick={scrollToTop}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
