import { MetaField } from "../api/generated";
import { NumberFormatter } from "./number-formatter";

export function getThcRange(unit: string, metafields: MetaField[]): string {
  return getNumberRange(unit, metafields, /(thc_percentage_from|thc_percentage_to)/i);
}

export function getCbdRange(unit: string, metafields: MetaField[]): string {
  return getNumberRange(unit, metafields, /(cbd_percentage_from|cbd_percentage_to)/i);
}

export function getNumberRange(unit: string, metafields: MetaField[], regex: RegExp): string {
  const values = metafields.filter((m) => regex.test(m.name)).map((m) => Number(m.value));

  // we need to overwrite the locale here, because the number formatter uses the i18n language
  // but the backend sends the numbers in en-GB format
  const min = Math.trunc(Number(NumberFormatter.formatPrecision(Math.min(...values), 2, "en-GB")));
  const max = Math.trunc(Number(NumberFormatter.formatPrecision(Math.max(...values), 2, "en-GB")));

  const checkedMin =
    Number.isNaN(min) && Number.isNaN(max)
      ? ""
      : Number.isNaN(min)
      ? ""
      : unit === "gram"
      ? `${min}%`
      : `${min}mg/ml`;
  const checkedMax =
    Number.isNaN(min) && Number.isNaN(max)
      ? ""
      : Number.isNaN(max)
      ? ""
      : unit === "gram"
      ? `${max}%`
      : `${max}mg/ml`;

  if (min === max) {
    return unit === "gram" ? `${min}%` : `${min}mg/ml`;
  }

  return `${checkedMin} - ${checkedMax}`;
}
