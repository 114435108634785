import i18n from "i18next";

const locale = () => (i18n.language === "de" ? "de-DE" : "en-GB");

export class NumberFormatter {
  static format(amount: number | bigint | string) {
    if (typeof amount === "string") {
      amount = Number(amount);
    }

    const formatter = new Intl.NumberFormat(locale(), {}).format(amount);

    return formatter;
  }

  static formatPrecision(
    amount: number | bigint | string,
    precision: number,
    overwriteLocale?: string,
  ) {
    if (!overwriteLocale) {
      overwriteLocale = locale();
    }

    if (typeof amount === "string") {
      amount = Number(amount);
    }

    const formatter = new Intl.NumberFormat(overwriteLocale, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }).format(amount);

    return formatter;
  }
}
